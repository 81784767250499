import { Link } from "gatsby";
import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnlyEmail from "../Forms/OnlyEmail";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

class Modal extends React.Component{

    constructor(props) {
        super(props);
        this.state = { 
            show: false,
        };
    }

    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ show: true });
    //     }, 13000);
    // }

    render(){
        const { show } = this.state;
        return(
            <div style={{ display: (show ? 'block' : 'none') }} className="bg-black bg-opacity-60 overflow-auto fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full">
                <div className="max-w-4xl mx-auto absolute left-0 right-0 overflow-hidden mt-24">
                        <div className="p-10 grid grid-cols-1 lg:grid-cols-2 w-full bg-green-500 rounded-lg">
                            <div>
                                <button onClick={() => this.setState({ show: !show })} class="absolute right-8 top-8"><svg className='w-6 h-6' viewBox="0 0 461 461" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M285.08 230.397L456.218 59.27C462.294 53.193 462.294 43.359 456.218 37.284L423.511 4.56501C420.598 1.65401 416.645 0.0150146 412.519 0.0150146C408.392 0.0150146 404.439 1.65401 401.526 4.56501L230.388 175.705L59.25 4.56501C56.337 1.65401 52.384 0.0150146 48.257 0.0150146C44.131 0.0150146 40.177 1.65401 37.265 4.56501L4.55799 37.284C-1.51901 43.359 -1.51901 53.193 4.55799 59.27L175.696 230.398L4.57499 401.505C-1.49901 407.582 -1.49901 417.416 4.57499 423.491L37.284 456.21C40.195 459.121 44.149 460.76 48.276 460.76C52.403 460.76 56.356 459.121 59.27 456.21L230.387 285.09L401.505 456.21C404.418 459.121 408.371 460.76 412.498 460.76C416.626 460.76 420.579 459.121 423.49 456.21L456.199 423.491C462.273 417.416 462.273 407.582 456.199 401.505L285.08 230.397Z" fill="white"/>
                                    </svg>
                                </button>
                                <div className="flex flex-col space-y-4">
                                    <div className="font-bold text-3xl text-white uppercase">Experimente a plataforma Shelfin com 3 meses gratuitamente.</div>
                                    <div className="text-white font-bold text-lg">Faça parte da Shelfin e utilize nossas ferramentas:</div>
                                    <div className="flex flex-col space-y-2 text-white font-medium">
                                        <div className="flex flex-row items-center text-lg space-x-2"><FontAwesomeIcon icon={faCheck}/><div>Gestão de treinamentos</div></div>
                                        <div className="flex flex-row items-center text-lg space-x-2"><FontAwesomeIcon icon={faCheck}/><div>Planos de desenvolvimento</div></div>
                                        <div className="flex flex-row items-center text-lg space-x-2"><FontAwesomeIcon icon={faCheck}/><div>Trilhas de carreira</div></div>
                                        <div className="flex flex-row items-center text-lg space-x-2"><FontAwesomeIcon icon={faCheck}/><div>NineBox</div></div>
                                    </div>
                                    
                                    <OnlyEmail/>
                                    
                                </div> 
                            </div>
                            <div className="flex items-center justify-center w-full h-full">
                                 {/* <img className="w-2/3" src={meses} /> */}
                                 <div className="w-96 back-modal h-96"></div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

export default Modal